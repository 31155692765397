

























































































































































































































































































import {Vue, Component} from 'vue-property-decorator';

// import { Notification, NotificationUpdate } from '@/api/api';
// import { updateNotification } from '@/api/calls';
import {appName} from '@/env';
import {EventBus} from '@/main';
import {mapGetters} from "vuex";

const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/dashboard');
  } else {
    next();
  }
};

@Component({
  computed: mapGetters({
    isSuperAdmin: 'api/isSuperAdmin',
    isTechSupport: 'api/isTechSupport',
    isOrgAdmin: 'api/isOrgAdmin',
    isLocationAdmin: 'api/isLocationAdmin'
  })
})
export default class Main extends Vue {
  public appName = appName;
  public unreadNotifications = 0;
  public showDrawer = true;
  public miniDrawer = false;
  public showNotificationsDrawer = false;
  public notificationsList = [];

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  // get miniDrawer() {
  //   return readDashboardMiniDrawer(this.$store);
  // }
  //
  // get showDrawer() {
  //   return readDashboardShowDrawer(this.$store);
  // }
  //
  // set showDrawer(value) {
  //   commitSetDashboardShowDrawer(this.$store, value);
  // }
  //
  // get showNotificationsDrawer() {
  //   return readUserNotificationsShowDrawer(this.$store);
  // }
  //
  // set showNotificationsDrawer(value) {
  //   commitSetUserNotificationsShowDrawer(this.$store, value);
  // }

  // get notificationsList() {
  //   return readUserNotifications(this.$store);
  // }

  public async created() {
    EventBus.$on('notification', (data) => {
      this.fetchNotifictations();
    });
    await this.fetchNotifictations();
  }

  public async fetchNotifictations() {
    // await dispatchGetNotifications(this.$store);
    //
    // this.unreadNotifications = this.notificationsList.filter(
    //     (x) => x.is_active,
    // ).length;
  }

  // public async disableNotification(notification: Notification) {
  //   const payload: NotificationUpdate = {
  //     alarm_id: notification.alarm_id,
  //     user_id: notification.user_id,
  //     is_active: false,
  //   };
  //
  //   await updateNotification(this.$store, notification.id!, payload);
  //   await this.fetchNotifictations();
  // }

  // public switchShowDrawer() {
  //   commitSetDashboardShowDrawer(
  //       this.$store,
  //       !readDashboardShowDrawer(this.$store),
  //   );
  // }
  //
  // public switchMiniDrawer() {
  //   commitSetDashboardMiniDrawer(
  //       this.$store,
  //       !readDashboardMiniDrawer(this.$store),
  //   );
  // }
  //
  // public switchShowNotificationsDrawer() {
  //   commitSetUserNotificationsShowDrawer(
  //       this.$store,
  //       !readUserNotificationsShowDrawer(this.$store),
  //   );
  // }

  public switchShowDrawer() {
    this.showDrawer = !this.showDrawer;
  }

  public switchMiniDrawer() {
    this.miniDrawer = !this.miniDrawer;
  }

  public switchShowNotificationsDrawer() {
    this.showNotificationsDrawer = !this.showNotificationsDrawer;
  }

  public async logout() {
    await this.$store.dispatch('api/logOut');
    await this.$store.dispatch('ui/navigateToLogin');
  }
}
